.add-client-btn{
    position: absolute;
    right:400px;
    top:80px
}
@media only screen and (max-width: 600px) {
    .add-client-btn {
        position: absolute;
        right:10px;
        top:80px
    }
  }