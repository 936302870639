.background{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.3;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    width: 400px;
    max-width: 90%;
    height: auto;
 
}
@media only screen and (max-width: 600px) {
    .modal-content {
        max-height: 500px;
        overflow-y: scroll;
    }
}

.modal-content h2 {
    margin-top: 0;
    display: flex;
    justify-content: center;
}
